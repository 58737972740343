import { Box, Stack } from "@mui/material";
import { VarianceFlags } from "../types";
import { OnHoldFlag } from "./onHoldFlag";
import { OpenOrderFlag } from "./openOrderFlag";
import { HasSupersessionsFlag } from "./hasSupersessionsFlag";
import { HasMultipleBinsFlag } from "./hasMultipleBinsFlag";

type FlagsColumnProps = {
    auditDate: Date
    varianceFlags: VarianceFlags
}

function FlagsColumn({ auditDate, varianceFlags }: FlagsColumnProps): JSX.Element {
    return (
        <Box
            sx={{
                display: "flex",
                height: "100%",
                justifyContent: "flex-end",
                width: "100%"
            }}
            >
            <Stack
                alignItems="center"
                direction="row"
                >
                <OnHoldFlag
                    auditDate={auditDate}
                    onHoldCount={varianceFlags.onHoldCount}
                    />
                <OpenOrderFlag
                    auditDate={auditDate}
                    openOrderCount={varianceFlags.openOrderCount}
                    />
                <HasMultipleBinsFlag
                    auditDate={auditDate}
                    hasMultipleBins={varianceFlags.hasMultipleBinsAssigned}
                    />
                <HasSupersessionsFlag
                    auditDate={auditDate}
                    hasSupersessions={varianceFlags.hasSupersession}
                    />
            </Stack>    
        </Box>
        
    );
}

export { FlagsColumn }