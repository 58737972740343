import React from "react";
import { useApiService } from "../../../base/providers";
import { dspPrintSetupInitialState, DspPrintSetupReducer } from "./reducer";
import { GetDspPrintInstructionsRespose, PartDetails } from "types/dsp/print";
import { TabType } from "./types";

type DspPrintSetupContextType = {
    binsCsv: string
    foundBinsList: string[]
    foundStockList: PartDetails[]
    selectedTab: TabType
    setBinsCsv: (input: string) => void
    setSelectedTab: (input: TabType) => void
    setStockCsv: (input: string) => void
    stockCsv: string
    submitBinLabels: () => Promise<void>
    submitStockLabels: () => Promise<void>
}

const DspPrintSetupContext = React.createContext<DspPrintSetupContextType | undefined>(undefined);

type DspPrintSetupContextProviderProps = {
    children: React.ReactNode
}

export function DspPrintSetupContextProvider(props: DspPrintSetupContextProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DspPrintSetupReducer, dspPrintSetupInitialState);

    const setSelectedTabCallback = React.useCallback((input: TabType): void => {
        dispatch({ type: "SET_SELECTED_TAB", payload: input });
    }, []);

    const setBinsCsvCallback = React.useCallback((input: string): void => {
        dispatch({ type: "SET_BINS_CSV", payload: input });
    }, []);

    const setStockCsvCallback = React.useCallback((input: string): void => {
        dispatch({ type: "SET_STOCK_CSV", payload: input });
    }, []);

    const submitBinLabelsCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const binRequests = state.binsCsv.split(",").map((item: string) => item.trim());

                const response: GetDspPrintInstructionsRespose = await apiService.dsp.generatePrintInstructions(binRequests, 24);

                dispatch({ type: "SET_BIN_INFORMATION", payload: response.foundStock });

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService.dsp, state.binsCsv]);

    const submitStockLabelsCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                const binRequests = state.stockCsv.split(",").map((item: string) => item.trim());

                const response: GetDspPrintInstructionsRespose = await apiService.dsp.generatePrintInstructions(binRequests, 1);

                dispatch({ type: "SET_STOCK_INFORMATION", payload: response.foundStock });

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService.dsp, state.stockCsv]);

    return (
        <DspPrintSetupContext.Provider
            value={{
                binsCsv: state.binsCsv,
                foundBinsList: state.foundBins,
                foundStockList: state.foundStock,
                selectedTab: state.selectedTab,
                setBinsCsv: setBinsCsvCallback,
                setSelectedTab: setSelectedTabCallback,
                setStockCsv: setStockCsvCallback,
                stockCsv: state.stockCsv,
                submitBinLabels: submitBinLabelsCallback,
                submitStockLabels: submitStockLabelsCallback,
            }}
            >
            {props.children}
        </DspPrintSetupContext.Provider>
    );
}

export function useDspPrintSetupContext(): DspPrintSetupContextType {
    const context = React.useContext(DspPrintSetupContext);

    if (!context) {
        throw new Error("useDspPrintSetupContext must be used within a DspPrintSetupContextProvider");
    }

    return context;
}