import { Stack, Typography } from "@mui/material";
import { DspPrintSetupContextProvider } from "./context";
import { BackToAuditsButton } from "../audit/details/buttons";
import { PrintFormResolver } from "./form";
import { PrintTabs } from "./tabs";
import { PrintTemplateResolver } from "./templateView";

export function DspPrintView(): JSX.Element {
    return (
        <DspPrintSetupContextProvider>
            <Stack
                direction="column"
                spacing={2}
                >
                <BackToAuditsButton />
                <Typography variant="h4">Print Labels</Typography>
                <PrintTabs />
                <PrintFormResolver />
                <PrintTemplateResolver />
            </Stack>
        </DspPrintSetupContextProvider>
    );
}