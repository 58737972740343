import React from 'react';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Tooltip } from '@mui/material';
import { formatDateForFlag } from '../dateFormatter';

type OnHoldFlagProps = {
    auditDate: Date
    onHoldCount: number
}

function OnHoldFlag({ auditDate, onHoldCount }: OnHoldFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);
    
    const auditDateStrMemo = React.useMemo((): string => {
        return formatDateForFlag(auditDate);
    }, [auditDate]);

    React.useEffect(() => {
        setComponent(() => {
            if (onHoldCount === 0) {
                return (<></>);
            }

            return (
                <Tooltip title={`${onHoldCount} on hold ${auditDateStrMemo}`}>
                    <AssignmentIndIcon color="error" />
                </Tooltip>
            );
        });
    }, [auditDateStrMemo, onHoldCount]);

    return (component);
}

export { OnHoldFlag }