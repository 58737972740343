export class PrintUtilities {
    static isVehiclePrintTemplateCode(code: string): boolean {
        return [ "vehicle3by3basic", "vehicle3by2basic", "vehicle5by4basic"].includes(code);
    }

    static isBinPrintTemplateCode(code: string): boolean {
        return [ "binLabelTemplate1", "binLabelTemplate2", "binLabelTemplate3" ].includes(code);
    }

    static isStockPrintTemplateCode(code: string): boolean {
        return [ "stockLabelTemplate1", "stockLabelTemplate2"].includes(code);
    }

    static isSpecialOrderPrintTemplateCode(code: string): boolean {
        return [ "specialOrderLabelTemplate1", "specialOrderLabelTemplate2"].includes(code);
    }
}