import React from "react";
import { dspBinLabelSetupInitialState, DspBinLabelSetupReducer } from "./reducer";
import { useApiService } from "../../../../../base/providers";
import { useSettingsContext } from "../../../../context";
import { GetBinPrintTemplateResponse } from "types/admin/printTemplate";

type DspBinLabelSetupContextType = {
    binPrintTemplates: GetBinPrintTemplateResponse[]
    savedTemplate?: number
    selectedTemplate?: number
    reloadData: () => void
    selectTemplate: (id: number) => void
    submit: () => Promise<void>
}

const DspBinLabelSetupContext = React.createContext<DspBinLabelSetupContextType | undefined>(undefined);

type DspBinLabelSetupContextProviderProps = {
    children: React.ReactNode
}

export function DspBinLabelSetupContextProvider(props: DspBinLabelSetupContextProviderProps): JSX.Element {
    const {
        binPrintTemplates,
        hasRecord,
        selectedBinTemplate,
        reloadSelectedTemplates
    } = useSettingsContext();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DspBinLabelSetupReducer, dspBinLabelSetupInitialState);

    const getSavedPrintTemplateCallback = React.useCallback((): void => {
        dispatch({ type: "SET_LOCATION_OPTION", payload: selectedBinTemplate?.id });
    }, [selectedBinTemplate]);

    const selectTemplateCallback = React.useCallback((id: number): void => {
        dispatch({ type: "SELECT_TEMPLATE", payload: id });
    }, []);

    const saveChangesCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                if (state.selectedTemplate === undefined) {
                    throw new Error("Template not selected.");
                }
                
                if (hasRecord === false) {
                    await apiService.printServiceLocation.create({ binPrintTemplateId: state.selectedTemplate });
                } else {
                    await apiService.printServiceLocation.updateBinTemplate(state.selectedTemplate);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, hasRecord, state.selectedTemplate]);

    React.useEffect(() => {
        getSavedPrintTemplateCallback();
    }, [getSavedPrintTemplateCallback]);

    return (
        <DspBinLabelSetupContext.Provider
            value={{
                binPrintTemplates: binPrintTemplates,
                savedTemplate: state.savedTemplate,
                selectedTemplate: state.selectedTemplate,
                reloadData: reloadSelectedTemplates,
                selectTemplate: selectTemplateCallback,
                submit: saveChangesCallback,
            }}
            >
            {props.children}
        </DspBinLabelSetupContext.Provider>
    );
}

export function useDspBinLabelSetupContext(): DspBinLabelSetupContextType {
    const context = React.useContext(DspBinLabelSetupContext);

    if (!context) {
        throw new Error("useDspLabelSetupContext must be used within a DspLabelSetupContextProvider");
    }

    return context;
}