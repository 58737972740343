import React from "react";
import { useStockLabelContext } from "../context";
import { StockLabelTemplate1, StockLabelTemplate2 } from "../templates";
import { PartDetails } from "types/dsp/print";

type StockLabelTemplateResolverProps = {
    partDetails: PartDetails
}

export function StockLabelTemplateResolver({ partDetails }: StockLabelTemplateResolverProps): JSX.Element {
    const { selectedTemplate } = useStockLabelContext();
    const [template, setTemplate] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setTemplate(() => {
            switch (selectedTemplate) {
                case "stockLabelTemplate1":
                    return <StockLabelTemplate1 partDetails={partDetails} />
                case "stockLabelTemplate2":
                    return <StockLabelTemplate2 partDetails={partDetails} />
                case undefined:
                    return <></>;
            }
        });
    }, [selectedTemplate, partDetails]);

    return (template);
}