import React from 'react';
import { SettingsTabType } from './types';
import { initialSettingsState, settingsReducer } from './reducer';
import { useApiService } from '../../base/providers';
import { GetPrintServiceLocationResponse } from 'types/print/printServiceLocation';
import {
    GetBinPrintTemplateResponse,
    GetSpecialOrderPrintTemplateResponse,
    GetStockPrintTemplateResponse,
    GetTypedPrintTemplateResponse,
    GetVehiclePrintTemplateResponse
} from 'types/admin/printTemplate';

interface SettingsContextProps {
    binPrintTemplates: GetBinPrintTemplateResponse[]
    hasRecord: boolean
    selectedBinTemplate?: GetBinPrintTemplateResponse
    selectedSpecialOrderTemplate?: GetSpecialOrderPrintTemplateResponse
    selectedTab: SettingsTabType,
    selectedStockTemplate?: GetStockPrintTemplateResponse
    selectedVehicleTemplate?: GetVehiclePrintTemplateResponse
    specialOrderPrintTemplates: GetSpecialOrderPrintTemplateResponse[]
    stockPrintTemplates: GetStockPrintTemplateResponse[]
    vehiclePrintTemplates: GetVehiclePrintTemplateResponse[]
    reloadSelectedTemplates: () => void
    selectTab: (tab: SettingsTabType) => void
}

const SettingsContext = React.createContext<SettingsContextProps | undefined>(undefined);

export const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(settingsReducer, initialSettingsState);

    const selectTabCallback = React.useCallback((tab: SettingsTabType) => {
        dispatch({ type: 'SET_TAB', payload: tab });
    }, [dispatch]);

    const getPrintTemplatesCallback = React.useCallback((): void => {
        apiService.adminPrintTemplate.getAll()
            .then((allPrintTemplates: GetTypedPrintTemplateResponse[]) => dispatch({ type: "SET_TEMPLATES", payload: allPrintTemplates }))
            .catch(err => console.error("Unable to get all print templates", err));
    }, [apiService.adminPrintTemplate]);

    const getSelectedTemplatesCallback = React.useCallback((): void => {
        apiService.printServiceLocation.getOne()
            .then((response: GetPrintServiceLocationResponse) => {
                dispatch({ type: "SET_PSL_INFO", payload: response});
            })
            .catch((err) => console.error("Unable to get print service locations", err));
    }, [apiService.printServiceLocation]);

    const hasRecordMemo = React.useMemo(() => {
        return state.selectedBinTemplate !== undefined ||
            state.selectedStockTemplate !== undefined ||
            state.selectedVehicleTemplate !== undefined;
    }, [state.selectedBinTemplate, state.selectedStockTemplate, state.selectedVehicleTemplate]);

    React.useEffect(() => {
        getSelectedTemplatesCallback();
    }, [getSelectedTemplatesCallback]);

    React.useEffect(() => {
        getPrintTemplatesCallback();
    }, [getPrintTemplatesCallback]);

    return (
        <SettingsContext.Provider value={{
                binPrintTemplates: state.binPrintTemplates,
                hasRecord: hasRecordMemo,
                selectedBinTemplate: state.selectedBinTemplate,
                selectedTab: state.selectedTab,
                selectedStockTemplate: state.selectedStockTemplate,
                selectedSpecialOrderTemplate: state.selectedSpecialOrderTemplate,
                selectedVehicleTemplate: state.selectedVehicleTemplate,
                stockPrintTemplates: state.stockPrintTemplates,
                specialOrderPrintTemplates: state.specialOrderPrintTemplates,
                vehiclePrintTemplates: state.vehiclePrintTemplates,
                reloadSelectedTemplates: getSelectedTemplatesCallback,
                selectTab: selectTabCallback,
            }}
            >
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettingsContext = (): SettingsContextProps => {
    const context = React.useContext(SettingsContext);

    if (!context) {
        throw new Error('useSettingsContext must be used within a SettingsProvider');
    }
    
    return context;
};