import { PartDetails } from "types/dsp/print";
import { TabType } from "../types";

type DspPrintSetupState = {
    binsCsv: string
    foundBins: string[]
    foundStock: PartDetails[]
    selectedTab: TabType
    stockCsv: string
}

type DspPrintSetupReducerAction =
    | { type: "SET_BINS_CSV", payload: string}
    | { type: "SET_STOCK_CSV", payload: string}
    | { type: "SET_BIN_INFORMATION", payload: PartDetails[]}
    | { type: "SET_STOCK_INFORMATION", payload: PartDetails[]}
    | { type: "SET_SELECTED_TAB", payload: TabType}

export const dspPrintSetupInitialState: DspPrintSetupState = {
    binsCsv: "",
    foundBins: [],
    foundStock: [],
    selectedTab: "stock",
    stockCsv: "",
};

export function DspPrintSetupReducer(state: DspPrintSetupState, action: DspPrintSetupReducerAction): DspPrintSetupState {
    switch (action.type) {
        case "SET_BINS_CSV":
            return {
                ...state,
                binsCsv: action.payload,
            };
        case "SET_STOCK_CSV":
            return {
                ...state,
                stockCsv: action.payload,
            };
        case "SET_BIN_INFORMATION":
            return {
                ...state,
                foundBins: action.payload.map((value: PartDetails) => value.binOne),
            };
        case "SET_STOCK_INFORMATION":
            return {
                ...state,
                foundStock: action.payload,
            };
        case "SET_SELECTED_TAB":
            return {
                ...state,
                selectedTab: action.payload,
            }
    }
}