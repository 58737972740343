import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Tooltip } from '@mui/material';
import { formatDateForFlag } from '../dateFormatter';

type OpenOrderFlagProps = {
    auditDate: Date
    openOrderCount: number
}

function OpenOrderFlag({ auditDate, openOrderCount }: OpenOrderFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);
    
    const auditDateStrMemo = React.useMemo((): string => {
        return formatDateForFlag(auditDate);
    }, [auditDate]);

    React.useEffect(() => {
        setComponent(() => {
            if (openOrderCount === 0) {
                return (<></>);
            }

            return (
                <Tooltip title={`${openOrderCount} pending open order(s) ${auditDateStrMemo}`}>
                    <LocalShippingIcon color="error" />
                </Tooltip>
            );
        });
    }, [auditDateStrMemo, openOrderCount]);

    return (component);
}

export { OpenOrderFlag }