import React from "react";
import { Stack, TextField, Button } from "@mui/material";
import { useDspPrintSetupContext } from "../../context";

export function StockLabelPrintForm(): JSX.Element {
    const {
        stockCsv,
        setStockCsv,
        submitStockLabels
    } = useDspPrintSetupContext();

    const onChangeStockNumbers = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        
        setStockCsv(e.target.value);
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        submitStockLabels();
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{ paddingTop: 2 }}
            >
            <TextField
                id="stockNumbers"
                label="Part Number(s)"
                multiline={true}
                onChange={onChangeStockNumbers}
                placeholder="Example: A77533, R31101"
                rows={4}
                value={stockCsv}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="outlined"
                >
                Generate Preview
            </Button>
        </Stack>
    );
}