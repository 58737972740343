import { Box, Tab, Tabs } from "@mui/material";
import { useDspPrintSetupContext } from "../context";
import { TabType } from "../context/types";

export function PrintTabs(): JSX.Element {
    const { selectedTab, setSelectedTab } = useDspPrintSetupContext();

    const onChangeSelectedTab = (e: React.SyntheticEvent, newValue: TabType) => {
        setSelectedTab(newValue);
    };
    
    return (
        <Box
            sx={{
                borderBottom: 1,
                borderColor: "divider",
            }}
            >
            <Tabs
                onChange={onChangeSelectedTab}
                value={selectedTab}
                sx={{
                    "& .MuiTab-root": {
                        paddingRight: "42px",
                    }
                }}
                >
                <Tab
                    label="Stock"
                    value="stock"
                    />
                <Tab
                    label="Bins"
                    value="bin"
                    />
            </Tabs>
        </Box>
    );
}