import { DspAuditVariance, DspBinMismatch, GetDspAuditVarianceResponse } from "types/dsp/audits"
import { AuditVarianceTabType } from "../../types"

type AuditVarianceStateType = {
    auditName: string
    auditVarianceQuantities: Map<number, number>
    auditVariances: DspAuditVariance[]
    binMismatches: DspBinMismatch[]
    rescanIds: Set<number>
    selectedPending: Set<number>
    selectedRescan: Set<number>
    selectedTab: AuditVarianceTabType
}

type ChangeVarianceQuantityPayloadType = {
    lightyearId: number
    newQuantity: number
}

type AuditVarianceReducerAction =
    | { type: "SET_AUDIT_VARIANCE", payload: GetDspAuditVarianceResponse }
    | { type: "CHANGE_VARIANCE_QUANTITY", payload: ChangeVarianceQuantityPayloadType }
    | { type: "RESET_VARIANCE_QUANTITY", payload: number }
    | { type: "SET_SELECTED_TAB", payload: AuditVarianceTabType }
    | { type: "TOGGLE_PENDING_SELECTED", payload: number }
    | { type: "TOGGLE_RESCAN_SELECTED", payload: number }
    | { type: "TOGGLE_FILTER", payload: number }
    | { type: "MARK_SELECTED_AS_RESCANS" }
    | { type: "CANCEL_SELECTED_RESCANS" }
    | { type: "REMOVE_SUCCESSFULLY_UPDATED_VARIANCES"}

const auditVarianceInitialState: AuditVarianceStateType = {
    auditName: "",
    auditVarianceQuantities: new Map<number, number>(),
    auditVariances: [],
    binMismatches: [],
    rescanIds: new Set<number>(),
    selectedPending: new Set<number>(),
    selectedRescan: new Set<number>(),
    selectedTab: "pending",
};

function AuditVarianceReducer(state: AuditVarianceStateType, action: AuditVarianceReducerAction): AuditVarianceStateType {
    switch (action.type) {
        case "SET_AUDIT_VARIANCE":
            const auditVariances = action.payload.auditVariances;

            const auditVarianceQuantities = new Map(auditVariances.map((value: DspAuditVariance) => [value.lightyearPartId, value.scannedCount + value.movementSinceScan]));

            const initialRescans = auditVariances.filter((value: DspAuditVariance) => value.rescanRequested)
                .map((value: DspAuditVariance) => value.lightyearPartId);

            return {
                ...state,
                auditName: action.payload.audit.auditName,
                auditVarianceQuantities: auditVarianceQuantities,
                auditVariances: auditVariances,
                binMismatches: action.payload.binMismatches,
                selectedPending: new Set<number>(),
                selectedRescan: new Set<number>(),
                rescanIds: new Set<number>(initialRescans),
            };
        case "CHANGE_VARIANCE_QUANTITY":
        { // DM 11/08/2024 Brackets for namespace
            const newMap = new Map(state.auditVarianceQuantities);
            newMap.set(action.payload.lightyearId, action.payload.newQuantity);

            return {
                ...state,
                auditVarianceQuantities: newMap,
            };
        }
        case "RESET_VARIANCE_QUANTITY":
        {
            const variance = state.auditVariances.find((value: DspAuditVariance) => value.lightyearPartId === action.payload)
            const newMap = new Map(state.auditVarianceQuantities);
            newMap.set(action.payload, variance?.scannedCount ?? 0);

            return {
                ...state,
                auditVarianceQuantities: newMap,
            };
        }
        case "SET_SELECTED_TAB":
        {
            return {
                ...state,
                selectedTab: action.payload,
            };
        }
        case "TOGGLE_PENDING_SELECTED":
        { // DM 11/08/2024 Brackets for namespace
            const newSelected = new Set(state.selectedPending);
            const lightyearId = action.payload;

            if (newSelected.has(lightyearId)) {
                newSelected.delete(lightyearId);
            } else {
                newSelected.add(lightyearId);
            }

            return {
                ...state,
                selectedPending: newSelected,
            };
        }    
        case "TOGGLE_RESCAN_SELECTED":
        { // DM 11/08/2024 Brackets for namespace
            const newSelected = new Set(state.selectedRescan);
            const lightyearId = action.payload;

            if (newSelected.has(lightyearId)) {
                newSelected.delete(lightyearId);
            } else {
                newSelected.add(lightyearId);
            }

            return {
                ...state,
                selectedRescan: newSelected,
            };
        }
        case "TOGGLE_FILTER":
        {
            const rescanIds = new Set<number>(state.rescanIds);
            const selectedPending = new Set<number>(state.selectedPending);
            const selectedRescan = new Set<number>(state.selectedRescan);

            if (rescanIds.has(action.payload)) {
                rescanIds.delete(action.payload);
                selectedRescan.delete(action.payload);
            } else {
                rescanIds.add(action.payload);
                selectedPending.delete(action.payload);
            }
            
            return {
                ...state,
                rescanIds: rescanIds,
                selectedPending: selectedPending,
                selectedRescan: selectedRescan,
            };
        }
        case "MARK_SELECTED_AS_RESCANS": // DM 02/06/2025 Move rescans without forcing a potentially wrong cache retrieval
        {
            const rescanIds = new Set<number>(state.rescanIds);
            state.selectedPending.forEach((newRescanId: number) => rescanIds.add(newRescanId));

            return {
                ...state,
                rescanIds: rescanIds,
                selectedPending: new Set<number>(),
            };
        }
        case "CANCEL_SELECTED_RESCANS": // DM 02/06/2025 Cancel rescans without forcing a potentially wrong cache retrieval
        {
            const rescanIds = new Set<number>(state.rescanIds);
            state.selectedRescan.forEach((selectedRescan: number) => rescanIds.delete(selectedRescan));

            return {
                ...state,
                rescanIds: rescanIds,
                selectedRescan: new Set<number>(),
            };
        }
        case "REMOVE_SUCCESSFULLY_UPDATED_VARIANCES": // DM 02/06/2025 Remove handwrites without forcing a potentially wrong cache retrieval
        {
            const remainingAuditVariances = state.auditVariances.filter((value: DspAuditVariance) => state.selectedPending.has(value.lightyearPartId) === false);
            const remainingAuditVarianceQuantities = new Map(remainingAuditVariances.map((value: DspAuditVariance) => [value.lightyearPartId, value.scannedCount]));

            return {
                ...state,
                auditVariances: remainingAuditVariances,
                auditVarianceQuantities: remainingAuditVarianceQuantities,
                selectedPending: new Set<number>(),
            };
        }
    }
}

export {
    auditVarianceInitialState,
    AuditVarianceReducer
}