import { Stack } from "@mui/material";
import { BinsButton, MismatchButton, VarianceButton } from "./buttons";

export function CompleteViewOptionsRow(): JSX.Element {
    return (
        <Stack
            direction="row"
            spacing={2}
            >
            <VarianceButton />
            <BinsButton />
            <MismatchButton />
        </Stack>
    );
}