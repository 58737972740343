type DspBinLabelSetupState = {
    savedTemplate?: number
    selectedTemplate?: number
}

type DspBinLabelSetupReducerAction =
    | { type: "SELECT_TEMPLATE", payload: number}
    | { type: "SET_LOCATION_OPTION", payload: number | undefined }

export const dspBinLabelSetupInitialState: DspBinLabelSetupState = {
    savedTemplate: undefined,
    selectedTemplate: undefined,
};

export function DspBinLabelSetupReducer(state: DspBinLabelSetupState, action: DspBinLabelSetupReducerAction): DspBinLabelSetupState {
    switch (action.type) {
        case "SELECT_TEMPLATE":
            return {
                ...state,
                selectedTemplate: action.payload,
            };
        case "SET_LOCATION_OPTION":
            return {
                ...state,
                savedTemplate: action.payload,
            };
    }
}