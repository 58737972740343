import { JSX } from "react/jsx-runtime";
import { SettingsProvider } from "./context";
import { TabView } from "./tabView";
import { Stack, Typography } from "@mui/material";
import { SettingsContentView } from "./contentView";

export function SettingsView(): JSX.Element {
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Typography variant="h4">Settings</Typography>
            <TabView />
            <SettingsContentView />
        </Stack>
    );
}

export { SettingsProvider}