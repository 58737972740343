import { SxProps, Theme } from "@mui/material";

export const stockLabelStyle2: SxProps<Theme> = {
    ".gridCardCenterer": {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        marginBottom: 1,
        marginTop: 1,
        width: "100%",
    },
    ".labelStock": {
        backgroundColor: "#FFFFFF",
        border: "solid 1px black",
        borderRadius: 2,
        height: "1in",
        padding: 0,
        width: "3in",
    },
    ".labelStock .labelStockLeft": {
        flexGrow: 1,
        paddingLeft: 1.5,
    },
    ".labelStock .labelStockLeft .labelStockPartNumber": {
        fontSize: "1.5em",
        marginTop: 0.35,
        textAlign: "left"
    },
    ".labelStock .labelStockLeft .labelStockBarcode": {
        marginTop: -1,
        textAlign: "left",
    },
    ".labelStock .labelStockLeft .labelStockDescription": {
        fontSize: "0.85em",
        marginTop: -1,
        textAlign: "left"
    },
    ".labelStock .labelStockBin": {
        display: "flex",
        paddingRight: 1,
        marginTop: 0.35,
        textAlign: "right",
    },
    "@media print": {
        "body": {
            imageRendering: "-webkit-optimize-contrast",
            printColorAdjust: "exact",
            "-webkit-print-color-adjust": "exact",
        },
        "*": {
            fontSmooth: "never",
            textRendering: "optimizeLegibility",
            "-webkit-font-smoothing": "none",
        },
        "@page": {
            height: "1in",
            width: "3in",
        },
        "body, body *": {
            border: "none",
            borderRadius: 0,
            display: "none",
            margin: 0,
            padding: 0,
        },
        "&" /* Target the parent x-data-grid */: {
            border: "none",
            margin: 0,
            padding: 0,
        },
        ".MuiDataGrid-topContainer, .MuiDataGrid-topContainer *, .MuiDataGrid-footerContainer, .MuiDataGrid-footerContainer *": {
            border: "none",
            display: "none",
        },
        ".MuiDataGrid-scrollbar, .MuiDataGrid-scrollbar *, .MuiDataGrid-scrollbarFiller": {
            border: "none",
            borderRadius: 0,
            display: "none",
            margin: 0,
            padding: 0,
        },
        ".MuiDataGrid-row, .MuiDataGrid-cell": {
            border: "none",
            borderRadius: 0,
            display: "block",
            margin: 0,
            padding: 0,
        },
        ".gridCardCenterer": {
            display: "block",
            justifyContent: "left",
            margin: 0,
            padding: 0,
        },
        ".labelStock": {
            border: "none",
            borderRadius: 0,
            display: "block",
            height: "1in",
            margin: 0,
            width: "3in",
        },
        ".labelStock .labelStockLeft .labelStockBarcode": {
            display: "block",
            zIndex: 0,
        },
        " .labelStock .labelStockLeft .labelStockBarcode svg": {
        },
        ".labelStock .labelStockLeft .labelStockDescription": {
            display: "block",
            zIndex: 1,
        },
        ".labelStock .labelStockBin": {
        },
    }
}