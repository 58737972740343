import React from "react";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Tooltip } from '@mui/material';
import { formatDateForFlag } from "../dateFormatter";

type HasSupersessionsFlagProps = {
    auditDate: Date
    hasSupersessions: boolean
}

function HasSupersessionsFlag({ auditDate, hasSupersessions }: HasSupersessionsFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);
    
    const auditDateStrMemo = React.useMemo((): string => {
        return formatDateForFlag(auditDate);
    }, [auditDate]);

    React.useEffect(() => {
        setComponent(() => {
            if (!hasSupersessions) {
                return (<></>);
            }

            return (
                <Tooltip title={`Supersession ${auditDateStrMemo}`}>
                    <SwapHorizontalCircleIcon color="error" />
                </Tooltip>
            );
        });
    }, [auditDateStrMemo, hasSupersessions]);

    return(component);
}

export { HasSupersessionsFlag }