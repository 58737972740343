import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { StockPrintTemplateCode } from "types/admin/printTemplate";

type StockLabelState = {
    selectedTemplate?: StockPrintTemplateCode
    selectedStyle: SxProps<Theme>
}

type StockLabelReducerAction =
    | { type: "SET_LOCATION_CODE", payload: StockPrintTemplateCode }
    | { type: "SET_SELECTED_STYLE", payload: SxProps<Theme> }

export const stockLabelInitialState: StockLabelState = {
    selectedTemplate: undefined,
    selectedStyle: {},
};

export function StockLabelReducer(state: StockLabelState, action: StockLabelReducerAction): StockLabelState {
    switch (action.type) {
        case "SET_LOCATION_CODE":
            return {
                ...state,
                selectedTemplate: action.payload
            };
        case "SET_SELECTED_STYLE":
            return {
                ...state,
                selectedStyle: action.payload
            };
    }
}