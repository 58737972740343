import {
    DspAuditBinReport,
    DspAuditVarianceReport,
    DspBinMismatch,
    GetAuditReportResponse,
} from "types/dsp/audits";
import { SelectedViewType } from "../types";

type FinishedAuditStateType = {
    auditVariances: DspAuditVarianceReport[]
    binMismatches: DspBinMismatch[]
    binReports: DspAuditBinReport[]
    selectedView: SelectedViewType
}

type FinishedAuditReducerActions = 
    | { type: "SET_AUDIT_DETAILS", payload: GetAuditReportResponse }
    | { type: "SET_SELECTED_VIEW", payload: "variance" | "bins" | "mismatch" }

const finishedAuditInitialState: FinishedAuditStateType = {
    auditVariances: [],
    binMismatches: [],
    binReports: [],
    selectedView: "variance",
};

function FinishedAuditReducer(state: FinishedAuditStateType, action: FinishedAuditReducerActions): FinishedAuditStateType {
    switch (action.type) {
        case "SET_AUDIT_DETAILS":
            return {
                ...state,
                auditVariances: action.payload.auditVariances,
                binMismatches: action.payload.binMismatches,
                binReports: action.payload.binReports,
            };
        case "SET_SELECTED_VIEW":
            return {
                ...state,
                selectedView: action.payload,
            };
    }
}

export {
    finishedAuditInitialState,
    FinishedAuditReducer,
}