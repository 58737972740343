import React from "react";
import { dspSpecialOrderLabelSetupInitialState, DspSpecialOrderLabelSetupReducer } from "./reducer";
import { useApiService } from "../../../../../base/providers";
import { useSettingsContext } from "../../../../context";
import { GetSpecialOrderPrintTemplateResponse } from "types/admin/printTemplate";

type DspSpecialOrderLabelSetupContextType = {
    savedTemplate?: number
    selectedTemplate?: number
    specialOrderPrintTemplates: GetSpecialOrderPrintTemplateResponse[]
    reloadData: () => void
    selectTemplate: (id: number) => void
    submit: () => Promise<void>
}

const DspSpecialOrderLabelSetupContext = React.createContext<DspSpecialOrderLabelSetupContextType | undefined>(undefined);

type DspSpecialOrderLabelSetupContextProviderProps = {
    children: React.ReactNode
}

export function DspSpecialOrderLabelSetupContextProvider(props: DspSpecialOrderLabelSetupContextProviderProps): JSX.Element {
    const {
        hasRecord,
        selectedSpecialOrderTemplate,
        specialOrderPrintTemplates,
        reloadSelectedTemplates
    } = useSettingsContext();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DspSpecialOrderLabelSetupReducer, dspSpecialOrderLabelSetupInitialState);

    const getSavedPrintTemplateCallback = React.useCallback((): void => {
        dispatch({ type: "SET_LOCATION_OPTION", payload: selectedSpecialOrderTemplate?.id });
    }, [selectedSpecialOrderTemplate]);

    const selectTemplateCallback = React.useCallback((id: number): void => {
        dispatch({ type: "SELECT_TEMPLATE", payload: id });
    }, []);

    const saveChangesCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                if (state.selectedTemplate === undefined) {
                    throw new Error("Template not selected.");
                }
                
                if (hasRecord === false) {
                    await apiService.printServiceLocation.create({ specialOrderPrintTemplateId: state.selectedTemplate });
                } else {
                    await apiService.printServiceLocation.updateSpecialOrderTemplate(state.selectedTemplate);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, hasRecord, state.selectedTemplate]);

    React.useEffect(() => {
        getSavedPrintTemplateCallback();
    }, [getSavedPrintTemplateCallback]);

    return (
        <DspSpecialOrderLabelSetupContext.Provider
            value={{
                specialOrderPrintTemplates: specialOrderPrintTemplates,
                savedTemplate: state.savedTemplate,
                selectedTemplate: state.selectedTemplate,
                reloadData: reloadSelectedTemplates,
                selectTemplate: selectTemplateCallback,
                submit: saveChangesCallback,
            }}
            >
            {props.children}
        </DspSpecialOrderLabelSetupContext.Provider>
    );
}

export function useDspSpecialOrderLabelSetupContext(): DspSpecialOrderLabelSetupContextType {
    const context = React.useContext(DspSpecialOrderLabelSetupContext);

    if (!context) {
        throw new Error("useDspSpecialOrderLabelSetupContext must be used within a DspSpecialOrderLabelSetupContextProvider");
    }

    return context;
}