import { GetPrintServiceLocationResponse } from "types/print/printServiceLocation";
import { SettingsTabType } from "../types";
import {
    GetBinPrintTemplateResponse,
    GetSpecialOrderPrintTemplateResponse,
    GetStockPrintTemplateResponse,
    GetTypedPrintTemplateResponse,
    GetVehiclePrintTemplateResponse
} from "types/admin/printTemplate";
import { PrintUtilities } from "../../../../utilities/print";
import { Print } from "@mui/icons-material";

export type SettingsState = {
    binPrintTemplates: GetBinPrintTemplateResponse[]
    selectedBinTemplate?: GetBinPrintTemplateResponse
    selectedSpecialOrderTemplate?: GetSpecialOrderPrintTemplateResponse
    selectedStockTemplate?: GetStockPrintTemplateResponse
    selectedTab: SettingsTabType
    selectedVehicleTemplate?: GetVehiclePrintTemplateResponse
    specialOrderPrintTemplates: GetSpecialOrderPrintTemplateResponse[]
    stockPrintTemplates: GetStockPrintTemplateResponse[]
    vehiclePrintTemplates: GetVehiclePrintTemplateResponse[]
};

type SettingsAction =
    | { type: 'SET_TAB', payload: SettingsTabType }
    | { type: 'SET_LANGUAGE', payload: string }
    | { type: 'SET_PSL_INFO', payload: GetPrintServiceLocationResponse }
    | { type: 'SET_TEMPLATES', payload: GetTypedPrintTemplateResponse[] }
    
export const initialSettingsState: SettingsState = {
    binPrintTemplates: [],
    selectedBinTemplate: undefined,
    selectedSpecialOrderTemplate: undefined,
    selectedStockTemplate: undefined,
    selectedTab: "parts-print",
    selectedVehicleTemplate: undefined,
    specialOrderPrintTemplates: [],
    stockPrintTemplates: [],
    vehiclePrintTemplates: [],
}

export const settingsReducer = (state: SettingsState, action: SettingsAction): SettingsState => {
    switch (action.type) {
        case 'SET_TAB':
            return {
                ...state,
                selectedTab: action.payload,
            };
        case 'SET_PSL_INFO':
            return {
                ...state,
                selectedBinTemplate: action.payload.binPrintTemplate,
                selectedSpecialOrderTemplate: action.payload.specialOrderPrintTemplate,
                selectedStockTemplate: action.payload.stockPrintTemplate,
                selectedVehicleTemplate: action.payload.vehiclePrintTemplate,
            };
        case 'SET_TEMPLATES':
            return {
                ...state,
                binPrintTemplates: action.payload.filter((value: GetTypedPrintTemplateResponse) => {
                        return PrintUtilities.isBinPrintTemplateCode(value.code);
                    }).map((value: GetTypedPrintTemplateResponse) => value as GetBinPrintTemplateResponse),
                stockPrintTemplates: action.payload.filter((value: GetTypedPrintTemplateResponse) => {
                        return PrintUtilities.isStockPrintTemplateCode(value.code);
                    }).map((value: GetTypedPrintTemplateResponse) => value as GetStockPrintTemplateResponse),
                vehiclePrintTemplates: action.payload.filter((value: GetTypedPrintTemplateResponse) => {
                        return PrintUtilities.isVehiclePrintTemplateCode(value.code);
                    }).map((value: GetTypedPrintTemplateResponse) => value as GetVehiclePrintTemplateResponse),
                specialOrderPrintTemplates: action.payload.filter((value: GetTypedPrintTemplateResponse) => {
                        return PrintUtilities.isSpecialOrderPrintTemplateCode(value.code);
                    }).map((value: GetTypedPrintTemplateResponse) => value as GetSpecialOrderPrintTemplateResponse),
            };
        default:
            return state;
    }
};