import React from "react";
import { useDspPrintSetupContext } from "../../../dsp/print/context";
import { useStockLabelContext } from "./context";
import { Button, Stack } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { columns } from "./dataGridColumns";
import { PartDetails } from "types/dsp/print";

type MappedRow = {
    id: number
    partDetails: PartDetails
}

export function StockLabelTemplateView(): JSX.Element {
    const { foundStockList } = useDspPrintSetupContext();
    const { selectedStyle } = useStockLabelContext();
    const apiRef = useGridApiRef();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        setMappedRows(() => {
            if (!foundStockList) {
                return [];
            }

            return foundStockList.map((partDetails: PartDetails, index: number) => {
                return {
                    id: index,
                    partDetails: partDetails,
                }
            });
        });
    }, [foundStockList]);

    const onClickPrint = (): void => {
        if (!apiRef || !apiRef.current) {
            return;
        }

        apiRef.current.exportDataAsPrint({
            hideFooter: true,
            hideToolbar: true,
            includeCheckboxes: false,
        });
    }

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Button
                onClick={onClickPrint}
                variant="contained"
                >
                Print
            </Button>
            <DataGrid
                apiRef={apiRef}
                columns={columns}
                getRowHeight={() => "auto"}
                rows={mappedRows}
                sx={selectedStyle}
                />
        </Stack>
    );
}