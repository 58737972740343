import React from "react";
import { dsvLabelSetupInitialState, DsvLabelSetupReducer } from "./reducer";
import { useApiService } from "../../../../base/providers";
import { useSettingsContext } from "../../../context";
import { GetVehiclePrintTemplateResponse } from "types/admin/printTemplate";

type DsvLabelSetupContextType = {
    printTemplates: GetVehiclePrintTemplateResponse[]
    savedTemplate?: number
    selectedTemplate?: number
    reloadData: () => void
    selectTemplate: (id: number) => void
    submit: () => Promise<void>
}

const DsvLabelSetupContext = React.createContext<DsvLabelSetupContextType | undefined>(undefined);

type DsvLabelSetupContextProviderProps = {
    children: React.ReactNode
}

export function DsvLabelSetupContextProvider(props: DsvLabelSetupContextProviderProps): JSX.Element {
    const {
        hasRecord,
        reloadSelectedTemplates,
        selectedVehicleTemplate,
        vehiclePrintTemplates,
    } = useSettingsContext();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DsvLabelSetupReducer, dsvLabelSetupInitialState);

    const getSavedPrintTemplateCallback = React.useCallback((): void => {
        dispatch({ type: "SET_LOCATION_OPTION", payload: selectedVehicleTemplate?.id })
    }, [selectedVehicleTemplate]);

    const selectTemplateCallback = React.useCallback((id: number): void => {
        dispatch({ type: "SELECT_TEMPLATE", payload: id });
    }, []);

    const saveChangesCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                if (state.selectedTemplate === undefined) {
                    throw new Error("Template not selected.");
                }
                
                if (hasRecord === false) {
                    await apiService.printServiceLocation.create({ vehiclePrintTemplateId: state.selectedTemplate });
                } else {
                    await apiService.printServiceLocation.updateVehicleTemplate(state.selectedTemplate);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, state.selectedTemplate, state.savedTemplate]);

    React.useEffect(() => {
        getSavedPrintTemplateCallback();
    }, [getSavedPrintTemplateCallback]);

    return (
        <DsvLabelSetupContext.Provider
            value={{
                printTemplates: vehiclePrintTemplates,
                savedTemplate: state.savedTemplate,
                selectedTemplate: state.selectedTemplate,
                reloadData: reloadSelectedTemplates,
                selectTemplate: selectTemplateCallback,
                submit: saveChangesCallback,
            }}
            >
            {props.children}
        </DsvLabelSetupContext.Provider>
    );
}

export function useDsvLabelSetupContext(): DsvLabelSetupContextType {
    const context = React.useContext(DsvLabelSetupContext);

    if (!context) {
        throw new Error("useDsvLabelSetupContext must be used within a DsvLabelSetupContextProvider");
    }

    return context;
}