import { GridColDef } from "@mui/x-data-grid";
import { DspAuditBinSimple } from "types/dsp/audits";

export const columns: GridColDef[] = [
    {
        field: "partNumber",
        flex: 1,
        headerName: "Part Number"
    },
    {
        cellClassName: "cellWordWrap",
        field: "matchedBins",
        flex: 2,
        headerName: "Matched Bins",
        valueFormatter: (value: DspAuditBinSimple[]) => value
            .map((valueInner: DspAuditBinSimple) => `${valueInner.binName} (${valueInner.scanCount})`)
            .join(", ")
    },
    {
        cellClassName: "cellWordWrap",
        field: "mismatchedBins",
        flex: 1,
        headerName: "Mismatched Bins",
        valueFormatter: (value: DspAuditBinSimple[]) => value
            .map((valueInner: DspAuditBinSimple) => `${valueInner.binName} (${valueInner.scanCount})`)
            .join(", ")
    },
    {
        field: "binOne",
        flex: 1,
        headerName: "Bin Loc",
        valueFormatter: (value: string) => value.length > 0 ? value : "-"
    },
    {
        field: "binTwo",
        flex: 1,
        headerName: "Bin 2",
        valueFormatter: (value: string) => value.length > 0 ? value : "-"
    },
    {
        field: "binThree",
        flex: 1,
        headerName: "Dirty Core Bin",
        valueFormatter: (value: string) => value.length > 0 ? value : "-"
    }
];