import { Card, CardContent, Typography, Box, Stack } from "@mui/material";
import Barcode from "react-barcode";
import { StockLabelTemplateProps } from "./props";

export function StockLabelTemplate2({ partDetails }: StockLabelTemplateProps): JSX.Element {
    const { partNumber, description, binOne } = partDetails;

    return (
        <Card
            sx={{
                height: "1in",
                width: "3in",
            }}
            >
            <CardContent className="labelStock">
                <Stack direction="row">
                    <Stack
                        alignItems="flex-begin"
                        className="labelStockLeft"
                        direction="column"
                        >
                        <Typography className="labelStockPartNumber">{partNumber}</Typography>
                        <Box className="labelStockBarcode">
                            <Barcode
                                displayValue={false}
                                height={30}
                                renderer="svg"
                                value={partNumber}
                                width={0.85}
                                />
                        </Box>
                        <Typography className="labelStockDescription">{description}</Typography>
                    </Stack>
                    <Typography className="labelStockBin">{binOne}</Typography>
                </Stack>
            </CardContent>
        </Card>
    );
}