import React from "react";
import { dspStockLabelSetupInitialState, DspStockLabelSetupReducer } from "./reducer";
import { useApiService } from "../../../../../base/providers";
import { useSettingsContext } from "../../../../context";
import { GetStockPrintTemplateResponse } from "types/admin/printTemplate";

type DspStockLabelSetupContextType = {
    savedTemplate?: number
    selectedTemplate?: number
    stockPrintTemplates: GetStockPrintTemplateResponse[]
    reloadData: () => void
    selectTemplate: (id: number) => void
    submit: () => Promise<void>
}

const DspStockLabelSetupContext = React.createContext<DspStockLabelSetupContextType | undefined>(undefined);

type DspStockLabelSetupContextProviderProps = {
    children: React.ReactNode
}

export function DspStockLabelSetupContextProvider(props: DspStockLabelSetupContextProviderProps): JSX.Element {
    const {
        hasRecord,
        selectedStockTemplate,
        stockPrintTemplates,
        reloadSelectedTemplates
    } = useSettingsContext();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DspStockLabelSetupReducer, dspStockLabelSetupInitialState);

    const getSavedPrintTemplateCallback = React.useCallback((): void => {
        dispatch({ type: "SET_LOCATION_OPTION", payload: selectedStockTemplate?.id });
    }, [selectedStockTemplate]);

    const selectTemplateCallback = React.useCallback((id: number): void => {
        dispatch({ type: "SELECT_TEMPLATE", payload: id });
    }, []);

    const saveChangesCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                if (state.selectedTemplate === undefined) {
                    throw new Error("Template not selected.");
                }
                
                if (hasRecord === false) {
                    await apiService.printServiceLocation.create({ stockPrintTemplateId: state.selectedTemplate });
                } else {
                    await apiService.printServiceLocation.updateStockTemplate(state.selectedTemplate);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, hasRecord, state.selectedTemplate]);

    React.useEffect(() => {
        getSavedPrintTemplateCallback();
    }, [getSavedPrintTemplateCallback]);

    return (
        <DspStockLabelSetupContext.Provider
            value={{
                stockPrintTemplates: stockPrintTemplates,
                savedTemplate: state.savedTemplate,
                selectedTemplate: state.selectedTemplate,
                reloadData: reloadSelectedTemplates,
                selectTemplate: selectTemplateCallback,
                submit: saveChangesCallback,
            }}
            >
            {props.children}
        </DspStockLabelSetupContext.Provider>
    );
}

export function useDspStockLabelSetupContext(): DspStockLabelSetupContextType {
    const context = React.useContext(DspStockLabelSetupContext);

    if (!context) {
        throw new Error("useDspStockLabelSetupContext must be used within a DspStockLabelSetupContextProvider");
    }

    return context;
}