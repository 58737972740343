import React from "react";
import { vinLabelInitialState, VinLabelReducer } from "./reducer";
import { VehiclePrintTemplateCode } from "types/admin/printTemplate";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { threeByTwoStyle } from "../style/threeByTwo";
import { threeByThreeStyle } from "../style/threeByThree";
import { fiveByFourStyle } from "../style/fiveByFour";
import { useSettingsContext } from "../../../../settings/context";

type VinLabelContextType = {
    selectedTemplate?: VehiclePrintTemplateCode
    selectedStyle: SxProps<Theme>
}

const VinLabelContext = React.createContext<VinLabelContextType | undefined>(undefined);

type VinLabelContextProviderProps = {
    children: React.ReactNode
}

export function VinLabelContextProvider(props: VinLabelContextProviderProps): JSX.Element {
    const { selectedVehicleTemplate } = useSettingsContext();
    const [state, dispatch] = React.useReducer(VinLabelReducer, vinLabelInitialState);

    React.useEffect(() => {
        if (!selectedVehicleTemplate) {
            return;
        }

        dispatch({ type: "SET_LOCATION_CODE", payload: selectedVehicleTemplate.code });
    }, [selectedVehicleTemplate]);

    React.useEffect(() => {
        switch (state.selectedTemplate) {
            case "vehicle3by2basic":
                dispatch({ type: "SET_SELECTED_STYLE", payload: threeByTwoStyle });
                break;
            case "vehicle3by3basic":
                dispatch({ type: "SET_SELECTED_STYLE", payload: threeByThreeStyle });
                break;
            case "vehicle5by4basic":
                dispatch({ type: "SET_SELECTED_STYLE", payload: fiveByFourStyle });
                break;
            case undefined:
                dispatch({ type: "SET_SELECTED_STYLE", payload: {} });
                break;
        }
    }, [state.selectedTemplate]);

    return (
        <VinLabelContext.Provider
            value={{
                selectedTemplate: state.selectedTemplate,
                selectedStyle: state.selectedStyle,
            }}
            >
            {props.children}
        </VinLabelContext.Provider>
    );
}

export function useVinLabelContext(): VinLabelContextType {
    const context = React.useContext(VinLabelContext);

    if (!context) {
        throw new Error("useVinLabelContext must be used within a VinLabelContextProvider");
    }

    return context;
}