import React from "react";
import { useDspPrintSetupContext } from "../context";
import { BinLabelContextProvider } from "../../../base/print/binLabel/context";
import { BinLabelTemplateView } from "../../../base/print/binLabel";
import { StockLabelContextProvider } from "../../../base/print/stockLabel/context";
import { StockLabelTemplateView } from "../../../base/print/stockLabel";

export function PrintTemplateResolver(): JSX.Element {
    const { selectedTab } = useDspPrintSetupContext();
    
    const componentMemo = React.useMemo((): JSX.Element => {
        switch (selectedTab) {
            case "bin":
                return (
                    <BinLabelContextProvider>
                        <BinLabelTemplateView />
                    </BinLabelContextProvider>
                );
            case "stock":
                return (
                    <StockLabelContextProvider>
                        <StockLabelTemplateView />
                    </StockLabelContextProvider>
                );
            default:
                return (<></>);
        }
    }, [selectedTab]);
    
    return componentMemo;
}