type DspStockLabelSetupState = {
    savedTemplate?: number
    selectedTemplate?: number
}

type DspStockLabelSetupReducerAction =
    | { type: "SELECT_TEMPLATE", payload: number}
    | { type: "SET_LOCATION_OPTION", payload: number | undefined }

export const dspStockLabelSetupInitialState: DspStockLabelSetupState = {
    savedTemplate: undefined,
    selectedTemplate: undefined
};

export function DspStockLabelSetupReducer(state: DspStockLabelSetupState, action: DspStockLabelSetupReducerAction): DspStockLabelSetupState {
    switch (action.type) {
        case "SELECT_TEMPLATE":
            return {
                ...state,
                selectedTemplate: action.payload
            };
        case "SET_LOCATION_OPTION":
            return {
                ...state,
                savedTemplate: action.payload,
            };
    }
}