import React from "react";
import { GenericDataGrid } from "../../../../../../base/dataGrid";
import { useFinishedAuditDetailsProvider } from "../../../view/complete/context";
import { MismatchMappedRow } from "./types";
import { DspAuditBinSimple, DspBinMismatch } from "types/dsp/audits";
import { columns } from "./columns";

export function CompleteMismatchDetailsDataGrid(): JSX.Element {
    const { binMismatches } = useFinishedAuditDetailsProvider();

    const mappedRows = React.useMemo((): MismatchMappedRow[] => {
        return binMismatches.map((value: DspBinMismatch, index: number): MismatchMappedRow => {
            return {
                id: index,
                partNumber: value.partNumber,
                matchedBins: value.scannedBins.filter((bin: DspAuditBinSimple) => bin.isMismatched === false),
                mismatchedBins: value.scannedBins.filter((bin: DspAuditBinSimple) => bin.isMismatched === true),
                binOne: value.binOne,
                binTwo: value.binTwo,
                binThree: value.binThree,
            };
        });
    }, [binMismatches]);
    
    return (
        <GenericDataGrid
            columns={columns}
            getRowHeight={() => "auto"}
            rows={mappedRows}
            sx={{
                "& .MuiDataGrid-cell": {
                    alignItems: "center",
                    display: "flex",
                },
                "& .cellWordWrap": {
                    overflow: "visible",
                    textOverflow: "clip",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
            }}
            />
    );
}