import React from "react";
import { CompleteVarianceDetailsDataGrid } from "./variance";
import { CompleteBinSummaryDetailsDataGrid } from "./binSummary";
import { useFinishedAuditDetailsProvider } from "../../view/complete/context";
import { CompleteMismatchDetailsDataGrid } from "./mismatch";

export function CompletedDataGridResolver(): JSX.Element {
    const { selectedView } = useFinishedAuditDetailsProvider();
    const [component, setComponent] = React.useState(<CompleteVarianceDetailsDataGrid />);

    React.useEffect(() => {
        setComponent(() => {
            switch (selectedView) {
                case "variance":
                    return <CompleteVarianceDetailsDataGrid />
                case "bins":
                    return <CompleteBinSummaryDetailsDataGrid />
                case "mismatch":
                    return <CompleteMismatchDetailsDataGrid />
            }
        });
    }, [selectedView]);

    return (component);
}