import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";

export const fiveByFourStyle: SxProps<Theme> = {
    ".gridCardCenterer": {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        marginBottom: 1,
        marginTop: 1,
        width: "100%",
    },
    ".labelVin": {
        backgroundColor: "#FFFFFF",
        borderRadius: 2,
        height: "5in",
        marginBottom: 1,
        marginTop: 0,
        padding: 0,
        width: "4in",
    },
    ".labelVin .labelVinTopForm": {
        height: "3in",
        margin: 0,
        width: "4in"
    },
    ".labelVin .labelVinTopForm .labelVinStockNumber": {
        fontSize: "5em",
        fontWeight: "bold",
    },
    ".labelVin .labelVinTopForm .labelVinIdentificationNumber": {
        fontSize: "1.1em",
        fontWeight: "bolder",
        margin: "-0.5em 0 0 0",
    },
    ".labelVin .labelVinTopForm .labelVinDescriptionContainer": {
        marginLeft: 2,
    },
    ".labelVin .labelVinTopForm .labelVinDescription": {
        fontSize: "1em",
        fontWeight: "bolder",
        margin: 0,
    },
    ".labelVin .labelVinBottomForm": {
        height: "1in",
        margin: 0,
        width: "2in",
    },
    ".labelVin .labelVinBottomForm .labelVinBottomLeft": {
        display: "inline-block",
        justifyContent: "left",
        width: "1.4in",
    },
    ".labelVin .labelVinBottomForm .labelVinBottomLeft .labelVinStockNumber": {
        fontSize: "1em",
        fontWeight: "bold",
        paddingLeft: 1,
    },
    ".labelVin .labelVinBottomForm .labelVinBottomLeft .labelVinBarcode": {
        display: "block",
        justifyContent: "left",
        marginLeft: 1,
    },
    ".labelVin .labelVinBottomForm .labelVinBottomLeft .labelVinIdentificationNumber": {
        fontSize: "0.7em",
        fontWeight: "bold",
        paddingLeft: 1,
        marginTop: -1,
        zIndex: 10,
    },
    ".labelVin .labelVinBottomForm .labelVinBottomRight": {
        display: "inline-block",
        justifyContent: "center",
        width: "0.4in",
    },
    ".labelVin .labelVinBottomForm .labelVinBottomRight .labelVinBottomDescription": {
        fontSize: "0.7em",
        overflow: "hidden",
    },
    "@media print": {
        "body": {
            imageRendering: "-webkit-optimize-contrast",
            printColorAdjust: "exact",
            "-webkit-print-color-adjust": "exact",
        },
        "*": {
            fontSmooth: "never",
            textRendering: "optimizeLegibility",
            "-webkit-font-smoothing": "none",
        },
        "@page": {
            height: "5in",
            width: "4in",
        },
        "body, body *": {
            border: "none",
            borderRadius: 0,
            display: "none",
            margin: 0,
            padding: 0,
        },
        "&" /* Target the parent x-data-grid */: {
            border: "none",
            margin: 0,
            padding: 0,
        },
        ".MuiDataGrid-topContainer, .MuiDataGrid-topContainer *, .MuiDataGrid-footerContainer, .MuiDataGrid-footerContainer *": {
            border: "none",
            display: "none",
        },
        ".MuiDataGrid-scrollbar, .MuiDataGrid-scrollbar *, .MuiDataGrid-scrollbarFiller": {
            border: "none",
            borderRadius: 0,
            display: "none",
            margin: 0,
            padding: 0,
        },
        ".MuiDataGrid-row, .MuiDataGrid-cell": {
            border: "none",
            borderRadius: 0,
            display: "block",
            margin: 0,
            padding: 0,
        },
        ".gridCardCenterer": {
            display: "block",
            justifyContent: "left",
            margin: 0,
            padding: 0,
        },
        ".labelVin": {
            border: "none",
            borderRadius: 0,
            display: "block",
            height: "5in",
            margin: 0,
            width: "4in",
        },
        ".labelVin .labelVinTopForm .labelVinBarcode": {
            display: "block",
            zIndex: 0,
        },
        " .labelVin .labelVinTopForm .labelVinBarcode svg": {
        },
        ".labelVin .labelVinTopForm .labelVinDescriptionContainer": {
        },
        ".labelVin .labelVinTopForm .labelVinDescriptionContainer .labelVinIdentificationNumber": {
            display: "block",
            marginTop: 0.25,
            zIndex: 1
        },
        ".labelVin .labelVinTopForm .labelVinDescriptionContainer .labelVinDescription": {
            display: "block",
            zIndex: 1
        },
        ".labelVin .labelVinBottomForm .labelVinBottomLeft": {
        },
        ".labelVin .labelVinBottomForm .labelVinBottomLeft .labelVinBarcode": {
            display: "block",
            zIndex: 0,
        },
        ".labelVin .labelVinBottomForm .labelVinBottomLeft .labelVinIdentificationNumber": {
            display: "block",
            marginTop: 0.25,
            zIndex: 1,
        }
    }
};