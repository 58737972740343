import { Divider, Stack, Typography } from "@mui/material";
import { DspBinLabelSetupContextProvider } from "./binForm/context";
import { BinLabelsSetupForm } from "./binForm";
import { StockLabelsSetupForm } from "./stockForm";
import { DspStockLabelSetupContextProvider } from "./stockForm/context";
import { DspSpecialOrderLabelSetupContextProvider } from "./specialOrderForm/context";
import { SpecialOrderLabelsSetupForm } from "./specialOrderForm";

export function PartsLabelLayoutsView(): JSX.Element {
    return (
            <Stack
                direction="column"
                spacing={2}
                >
                <Typography variant="h5">Bin Label Default</Typography>
                <Typography variant="body2">Choose which label you would like as your default (only choose one at a time)</Typography>
                <DspBinLabelSetupContextProvider>
                    <BinLabelsSetupForm />
                </DspBinLabelSetupContextProvider>
                <Divider />
                <Typography variant="h5">Stock Label Default</Typography>
                <Typography variant="body2">Choose which label you would like as your default (only choose one at a time)</Typography>
                <DspStockLabelSetupContextProvider>
                    <StockLabelsSetupForm />
                </DspStockLabelSetupContextProvider>
                <Divider />
                <Typography variant="h5">Special Order Label Default</Typography>
                <Typography variant="body2">Choose which label you would like as your default (only choose one at a time)</Typography>
                <DspSpecialOrderLabelSetupContextProvider>
                    <SpecialOrderLabelsSetupForm />
                </DspSpecialOrderLabelSetupContextProvider>
            </Stack>
    );
}