import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { StockLabelTemplateProps } from "./props";
import Barcode from "react-barcode";

export function StockLabelTemplate1({ partDetails }: StockLabelTemplateProps): JSX.Element {
    const { partNumber, description } = partDetails;

    return (
        <Card
            sx={{
                height: "1in",
                width: "3in",
            }}
            >
            <CardContent className="labelStock">
                <Stack
                    direction="column"
                    alignItems="center"
                    >
                    <Typography className="labelStockPartNumber">{partNumber}</Typography>
                    <Box className="labelStockBarcode">
                        <Barcode
                            displayValue={false}
                            height={30}
                            renderer="svg"
                            value={partNumber}
                            width={1.0}
                            />
                    </Box>
                    <Typography className="labelStockDescription">{description}</Typography>
                </Stack>
            </CardContent>
        </Card>
    );
}