import React from "react";
import { Button, Stack } from "@mui/material";
import { useDspBinLabelSetupContext } from "./context";
import { GetTypedPrintTemplateResponse } from "types/admin/printTemplate";
import { SelectButtonTemplate } from "../selectTemplate";

export function BinLabelsSetupForm(): JSX.Element {
    const {
        binPrintTemplates,
        savedTemplate,
        selectedTemplate,
        reloadData,
        selectTemplate,
        submit
    } = useDspBinLabelSetupContext();
    const [options, setOptions] = React.useState<JSX.Element[]>([]);

    React.useEffect(() => {
        setOptions(() => {
            if (!binPrintTemplates) {
                return [];
            }

            return binPrintTemplates.map((printTemplate: GetTypedPrintTemplateResponse): JSX.Element => {
                return (
                    <SelectButtonTemplate
                        description={printTemplate.description}
                        height={printTemplate.height}
                        imgSrc={printTemplate.templateImageSvgPath}
                        savedTemplate={savedTemplate}
                        selectedTemplate={selectedTemplate}
                        value={printTemplate.id}
                        width={printTemplate.width}
                        selectTemplate={selectTemplate}
                        />
                );
            });
        });
    }, [binPrintTemplates, savedTemplate, selectedTemplate, selectTemplate]);

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        submit()
            .then(() => reloadData())
            .catch((err) => console.error("Unable to submit change to vehicle print template", err));
    }

    return (
        <Stack
            component="form"
            direction="column"
            spacing={2}
            >
            <Stack
                direction="row"
                justifyContent="space-evenly"
                sx={{
                    ".MuiBadge-badge": {
                        backgroundColor: "#0D74A1"
                    }
                }}
                >
                {options}
            </Stack>
            <Button
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Change Default
            </Button>
        </Stack>
    );
}