export function formatDateForFlag(date: Date): string {
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit'
    });

    const dateObj = new Date(date);

    const timePart = timeFormatter.format(dateObj).toLowerCase();
    const datePart = dateFormatter.format(dateObj).replace(',','');

    return `as of ${timePart} on ${datePart}`;
}