import React from "react";
import { Badge, Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

type SelectButtonTemplateProps = {
    description: string
    height: number
    imgSrc: string
    savedTemplate?: number
    selectedTemplate?: number
    value: number
    width: number
    selectTemplate: (value: number) => void
}

export function SelectButtonTemplate({
    description,
    height,
    imgSrc,
    savedTemplate,
    selectedTemplate,
    value,
    width,
    selectTemplate,
}: SelectButtonTemplateProps): JSX.Element {

    const isSelectedMemo = React.useMemo((): boolean => {
        return value === selectedTemplate;
    }, [selectedTemplate, value]);

    const isBadgeInvisibleMemo = React.useMemo((): boolean => {
        return value !== savedTemplate;
    }, [savedTemplate, value]);

    const onClickOption = (): void => {
        selectTemplate(value);
    }

    return (
        <Badge
            badgeContent={<CheckIcon />}
            color="primary"
            invisible={isBadgeInvisibleMemo}
            >
            <Card
                sx={{
                    border: `2px solid ${isSelectedMemo ? "#0D74A1" : "#EBE9F1"}`,
                    borderRadius: 2,
                    height: `${height}in`,
                    width: `${width}in`,
                }}
                >
                <CardActionArea
                    onClick={onClickOption}
                    sx={{
                        height: "100%"
                    }}
                    >
                    <CardContent sx={{
                        padding: 0
                    }}>
                        <CardMedia
                            alt={description}
                            component="img"
                            src={imgSrc}
                            sx={{
                                height: `${height}in`,
                                width: `${width}in`
                            }}
                            />
                    </CardContent>
                </CardActionArea>
            </Card>
        </Badge>
        
    );
}