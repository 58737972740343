import { Button, Chip } from "@mui/material";
import { useFinishedAuditDetailsProvider } from "../../../context";

export function MismatchButton(): JSX.Element {
    const { binMismatches, changeSelectedView } = useFinishedAuditDetailsProvider();

    const onClickVariancesButton = (): void => {
        changeSelectedView("mismatch");
    }

    return (
        <Button onClick={onClickVariancesButton}>
            Bin Mismatches&nbsp;<Chip label={binMismatches.length} />
        </Button>
    );
}