type DsvLabelSetupState = {
    savedTemplate?: number
    selectedTemplate?: number
}

type DsvLabelSetupReducerAction =
    | { type: "SELECT_TEMPLATE", payload: number}
    | { type: "SET_LOCATION_OPTION", payload: number | undefined }

export const dsvLabelSetupInitialState: DsvLabelSetupState = {
    savedTemplate: undefined,
    selectedTemplate: undefined,
};

export function DsvLabelSetupReducer(state: DsvLabelSetupState, action: DsvLabelSetupReducerAction): DsvLabelSetupState {
    switch (action.type) {
        case "SELECT_TEMPLATE":
            return {
                ...state,
                selectedTemplate: action.payload
            };
        case "SET_LOCATION_OPTION":
            return {
                ...state,
                savedTemplate: action.payload
            };
    }
}