import React from "react";
import { stockLabelInitialState, StockLabelReducer } from "./reducer";
import { StockPrintTemplateCode } from "types/admin/printTemplate";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { stockLabelStyle1, stockLabelStyle2 } from "../style";
import { useSettingsContext } from "../../../../settings/context";

type StockLabelContextType = {
    selectedTemplate?: StockPrintTemplateCode
    selectedStyle: SxProps<Theme>
}

const StockLabelContext = React.createContext<StockLabelContextType | undefined>(undefined);

type StockLabelContextProviderProps = {
    children: React.ReactNode
}

export function StockLabelContextProvider(props: StockLabelContextProviderProps): JSX.Element {
    const { selectedStockTemplate } = useSettingsContext();
    const [state, dispatch] = React.useReducer(StockLabelReducer, stockLabelInitialState);

    React.useEffect(() => {
        if (!selectedStockTemplate) {
            return;
        }
        dispatch({ type: "SET_LOCATION_CODE", payload: selectedStockTemplate.code });
    }, [selectedStockTemplate]);

    React.useEffect(() => {
        switch (state.selectedTemplate) {
            case "stockLabelTemplate1":
                dispatch({ type: "SET_SELECTED_STYLE", payload: stockLabelStyle1 });
                break;
            case "stockLabelTemplate2":
                dispatch({ type: "SET_SELECTED_STYLE", payload: stockLabelStyle2 });
                break;
            case undefined:
                dispatch({ type: "SET_SELECTED_STYLE", payload: {} });
                break;
        }
    }, [state.selectedTemplate]);

    return (
        <StockLabelContext.Provider
            value={{
                selectedTemplate: state.selectedTemplate,
                selectedStyle: state.selectedStyle,
            }}
            >
            {props.children}
        </StockLabelContext.Provider>
    );
}

export function useStockLabelContext(): StockLabelContextType {
    const context = React.useContext(StockLabelContext);

    if (!context) {
        throw new Error("useStockLabelContext must be used within a StockLabelContextProvider");
    }

    return context;
}