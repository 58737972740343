import React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Tooltip } from '@mui/material';
import { formatDateForFlag } from '../dateFormatter';

type HasMultipleBinsFlagProps = {
    auditDate: Date
    hasMultipleBins: boolean
}

function HasMultipleBinsFlag({ auditDate, hasMultipleBins }: HasMultipleBinsFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    const auditDateStrMemo = React.useMemo((): string => {
        return formatDateForFlag(auditDate);
    }, [auditDate]);

    React.useEffect(() => {
        setComponent(() => {
            if (!hasMultipleBins) {
                return (<></>);
            }

            return (
                <Tooltip title={`Assigned to multiple bins ${auditDateStrMemo}`}>
                    <ViewListIcon color="primary" />
                </Tooltip>
            );
        });
    }, [auditDateStrMemo, hasMultipleBins]);

    return (component);
}

export { HasMultipleBinsFlag }