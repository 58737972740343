import React from "react";
import { useDspPrintSetupContext } from "../context";
import { BinLabelPrintForm } from "./bin";
import { StockLabelPrintForm } from "./stock";

export function PrintFormResolver(): JSX.Element {
    const { selectedTab } = useDspPrintSetupContext();
    
    const componentMemo = React.useMemo((): JSX.Element => {
        switch (selectedTab) {
            case "bin":
                return (<BinLabelPrintForm />);
            case "stock":
                return (<StockLabelPrintForm />);
            default:
                return (<></>);
        }
    }, [selectedTab]);
    
    return componentMemo;
}